<template>
    <v-dialog
        v-model="dialog"
        fullscreen
        persistent
        scrollable
        transition="dialog-bottom-transition"
    >
        <template #activator="{ props: dialogProps }">
            <v-btn
                rounded="sm"
                class="px-0"
                color="blue"
                min-width="36"
                v-bind="dialogProps"
                variant="tonal"
                width="36"
            >
                <v-icon
                    icon="$license"
                />
            </v-btn>
        </template>

        <v-card rounded>
            <v-toolbar
                :color="toolbarColor"
                class="border-b"
                elevation="1"
                style="z-index: 2"
                tile
                title="Licenze open source"
            >
                <template #append>
                    <v-btn
                        icon="$close"
                        @click="dialog = !dialog"
                    />
                </template>
            </v-toolbar>

            <v-card-text class="pa-0">
                <v-expansion-panels
                    v-if="licenses.length > 0"
                    variant="accordion"
                >
                    <v-expansion-panel v-for="license in licenses">
                        <v-expansion-panel-title>
                            {{ license.name }}

                            <v-chip
                                class="ml-2 rounded-pill"
                                color="green"
                                density="compact"
                                label
                                variant="tonal"
                            >
                                <small>{{ license.version }}</small>
                            </v-chip>
                        </v-expansion-panel-title>
                        <v-expansion-panel-text class="text-body-2">
                            <h3 v-if="license.description">{{ license.description }}</h3>
                            <p v-if="license.author?.name">Author: {{ license.author.name }}</p>
                            <p>License: {{ license.license }}</p>
                            <p
                                v-if="license.licenseText"
                                v-html="license.licenseText"
                            />
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script lang="ts" setup>
import { orderBy } from "@/@projlib/lodash";
import { HttpCodes } from "@/@stdlib/network";
import type { LicensePluginJsonRecord } from "@darcas/rollup-plugin-license-json";
import axios from "axios";
import { ref, watch } from "vue";

defineProps({
    toolbarColor: String
})

const dialog = ref(false)
const licenses = ref<LicensePluginJsonRecord[]>([])

watch(dialog, async dialog => {
    if (dialog) {
        try {
            const response = await axios.get('/licenses.json', {
                headers: {
                    Accept: 'application/json',
                },
            })

            if (response.status === HttpCodes.OK) {
                licenses.value = orderBy(response.data, ['name'], ['asc'])
            }
        } catch (e) {
            //
        }
    }
})
</script>

<style lang="scss" scoped>

</style>
